//
// Colour Palette
//
$black: #000;
$white: #fff;

$blue: #6facde;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #b70000;
$red2: #f1cccc;
$orange: #df6534;
$yellow: #f0b323;
$yellow2: #f9e1a7;
$green: #3f8367;
$green2: #e8ead2;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$primary: $blue;
$secondary1: #0075bc;
$secondary2: #2b5a80;
$tertiary1: $orange;
$tertiary4: #898d8d;

$background-grey: #e7e8e8;
$border-grey: #606363;
$border-blue: #00263e;

$background-light-grey: #f0f0f0;

$font-blue: $border-blue;
$font-grey: #606363;
$font-dark-grey: #444444;

$primary-blue2: #e2eef8;
$primary-blue3: #c5def2;

$secondary-blue1: #e6f1f8;
$secondary-blue7: #0063a0;

$secondary-blue-digital1: #eaeff2;
$secondary-blue-digital7: #254d6d;

$secondary-grey2: #f3f4f4;
$secondary-grey3: #d0d1d1;

$secondary-green1: #f3f4e9;
$secondary-green6: #8a941e;

$secondary-yellow1: #fef7e9;
$secondary-yellow6: #f0b323;

$error-message-red1: #f8e6e6;
$error-message-red3: #e29999;
$error-message-red6: $red;

$focus-color: $blue;
$disabled-grey: $background-grey;

$primary-bg: $secondary2;
$primary-hover: $secondary-blue-digital7;
$primary-active: #112433;
$primary-disabled: $disabled-grey;

$indicator-red: $error-message-red3;
$indicator-green: #a5d4c0;
$indicator-yellow: #fcf0d3;

$critical-red: #d46666;
$normal-green: #78bfa2;
$warning-yellow: #f6d17b;

$grid-hover: #f1f7fc;

$tertiary-bg: #d5dee6;

$svg-color00263E: invert(8%) sepia(97%) saturate(2424%) hue-rotate(183deg) brightness(91%)
  contrast(105%);
$svg-colorFFFFFF: brightness(0) saturate(100%) invert(100%) sepia(74%) saturate(0%)
  hue-rotate(31deg) brightness(98%) contrast(105%);
$svg-color606363: brightness(0) saturate(100%) invert(39%) sepia(3%) saturate(397%)
  hue-rotate(140deg) brightness(95%) contrast(89%);
//kpi red arrow
$svg-color800000: brightness(0) saturate(100%) invert(13%) sepia(23%) saturate(7464%)
  hue-rotate(347deg) brightness(106%) contrast(126%);
//kpi red green
$svg-color275240: brightness(0) saturate(100%) invert(24%) sepia(44%) saturate(478%)
  hue-rotate(103deg) brightness(96%) contrast(88%);
//kpi yellow arrow
$svg-colorA87D19: brightness(0) saturate(100%) invert(65%) sepia(46%) saturate(6497%)
  hue-rotate(20deg) brightness(89%) contrast(80%);
$svg-color2b5a80: invert(28%) sepia(69%) saturate(477%) hue-rotate(165deg) brightness(93%)
  contrast(88%);

$font-family: 'PT Sans', sans-serif;

//
// Boostrap Form Control CSS variable overrides
//
$component-active-color: $secondary2;
$component-active-bg: $white;

$form-check-input-width: 1.125em;
$form-check-padding-start: 1.725em;
$form-check-margin-bottom: 0;
$form-check-label-color: $secondary2;

$form-check-input-border-radius: 0;
$form-check-input-focus-box-shadow: 0 0 0 4px $focus-color;

$form-check-input-disabled-opacity: 1;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;

$form-label-font-weight: bold;
$form-label-margin-bottom: 0;
$form-label-color: $secondary2;

$input-bg: $white;
$input-color: $secondary2;
$input-placeholder-color: $font-grey;

$input-border-radius: 0;
$input-border-color: $secondary2;

$input-btn-font-size: 16px;
$input-btn-focus-color: $secondary2;

$input-focus-border-color: $secondary2;
$input-focus-width: 1px;
$input-focus-box-shadow: 0 0 0 $input-focus-width $secondary2;

$form-text-margin-top: 8px;
$form-text-font-size: 12px;
$form-text-font-weight: normal;
$form-text-color: $red;

@mixin form-control-state() {
  .form-control,
  .form-select {
    padding: 9px 16px;
    color: $font-blue;

    &:disabled {
      background-color: $disabled-grey;
      color: $font-grey;
      border-color: $border-grey;
    }
  }

  .form-select {
    padding: 9px 36px 9px 16px;
  }
}

@mixin form-control-check-state() {
  .form-check-input {
    border: 2px solid $secondary2;

    &:hover,
    &:enabled + .form-check-label:hover {
      cursor: pointer;
    }
    &:disabled {
      background-color: $disabled-grey;
      border-color: $border-grey;

      & + .form-check-label {
        color: $font-grey;
      }
    }
  }
}

@mixin form-control-error-state() {
  .form-text {
    display: none;
    line-height: 16px;
  }

  &.ng-invalid.ng-touched {
    .form-control,
    .form-select {
      border-color: $red;

      &:focus {
        box-shadow: 0 0 0 $input-focus-width $red;
      }
    }
    .form-check-input {
      border-color: $red;
    }
    .form-text {
      display: block;
    }
  }
}

@mixin form-control-inline-label() {
  .re-inline-label {
    display: flex;
    gap: 8px;

    .re-control {
      flex: 1 1 auto;
    }
  }
}

//
// Bootstrap Button CSS variable overrides
//
@mixin button(
  $bg-color,
  $border-color,
  $text-color,
  $focus-color,
  $hover-bg-color,
  $hover-border-color,
  $hover-text-color,
  $active-bg-color,
  $active-border-color,
  $active-text-color,
  $disabled-bg-color,
  $disabled-border-color,
  $disabled-text-color
) {
  --bs-btn-bg: #{$bg-color};
  --bs-btn-border-color: #{$border-color};
  --bs-btn-color: #{$text-color};
  --bs-btn-focus-box-shadow: 0 0 0 4px #{$focus-color};
  --bs-btn-font-weight: bold;
  --bs-btn-hover-bg: #{$hover-bg-color};
  --bs-btn-hover-border-color: #{$hover-border-color};
  --bs-btn-hover-color: #{$hover-text-color};
  --bs-btn-active-bg: #{$active-bg-color};
  --bs-btn-active-border-color: #{$active-border-color};
  --bs-btn-active-color: #{$active-text-color};
  --bs-btn-disabled-bg: #{$disabled-bg-color};
  --bs-btn-disabled-border-color: #{$disabled-border-color};
  --bs-btn-disabled-color: #{$disabled-text-color};
  --bs-btn-disabled-opacity: 1;
  --bs-btn-border-radius: 4px;
}

@mixin button-sm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  padding: 9px 12px;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 14px;
  min-height: 36px;
  text-transform: uppercase;

  &:disabled {
    img {
      filter: $svg-color606363;
    }
  }
}

@mixin button-md {
  @include button-sm();

  padding: 13px 16px;
  gap: 12px;
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 16px;
  min-height: 44px;
}

@mixin button-lg {
  @include button-sm();

  padding: 15px 24px;
  gap: 12px;
  font-size: 20px;
  letter-spacing: 1.5px;
  line-height: 20px;
  min-height: 52px;
}

@mixin button-primary {
  @include button(
    // Default: background, border, font, focus colours
    $primary-bg,
    $primary-bg,
    $white,
    $focus-color,
    // Hover
    $primary-hover,
    $primary-hover,
    $white,
    // Active
    $primary-active,
    $primary-active,
    $white,
    // Disabled
    $disabled-grey,
    $disabled-grey,
    $font-grey
  );

  &:not(:disabled) img {
    filter: $svg-colorFFFFFF;
  }
  &:focus:not(:hover) {
    background-color: $primary-bg;
  }
}

@mixin button-secondary {
  @include button(
    // Default: background, border, font, focus colours
    $white,
    $secondary2,
    $font-blue,
    $focus-color,
    // Hover
    $white,
    $secondary2,
    $font-blue,
    // Active
    $white,
    $secondary2,
    $font-blue,
    // Disabled
    $disabled-grey,
    $border-grey,
    $font-grey
  );

  &:hover {
    box-shadow: 0 0 0 1px #{$secondary2};

    &:focus-visible {
      box-shadow:
        0 0 0 1px #{$secondary2},
        0 0 0 5px #{$focus-color};
    }
    &:active {
      box-shadow: inherit;
    }
  }
}

@mixin button-tertiary {
  @include button(
    // Default: background, border, font, focus colours
    transparent,
    transparent,
    $font-blue,
    $focus-color,
    // Hover
    $tertiary-bg,
    $tertiary-bg,
    $font-blue,
    // Active
    transparent,
    transparent,
    $font-blue,
    // Disabled
    $disabled-grey,
    $disabled-grey,
    $font-grey
  );

  &:focus:not(:hover) {
    background-color: transparent;
  }
}

//
// Bootstrap Close Button CSS variable overrides
//
$btn-close-width: 16px;
$btn-close-color: $font-blue;
$btn-close-padding-x: 12px;
$btn-close-padding-y: 10px;
$btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' fill='#{$btn-close-color}'><!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d='M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z'/></svg>");
$btn-close-focus-shadow: 0 0 0 4px $focus-color;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 1;
$btn-close-focus-opacity: 1;

//
// Bootstrap Select CSS variable overrides
//
$form-select-indicator-color: $font-blue;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='#{$form-select-indicator-color}'><!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill-rule='evenodd' d='M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z'/></svg>");

//
// Bootstrap Accordion CSS variable overrides
//
$accordion-padding-y: 16px;
$accordion-padding-x: 24px;

$accordion-border-color: $secondary-grey3;
$accordion-border-width: 1px;
$accordion-border-radius: 4px;

$accordion-inner-border-radius: 4px;

$accordion-button-color: $secondary2;
$accordion-button-active-bg: $white;
$accordion-button-active-color: $font-blue;
$accordion-button-focus-box-shadow: 0 0 0 4px $focus-color;

$accordion-icon-width: 1rem;
$accordion-icon-color: $secondary2;
$accordion-icon-active-color: $font-blue;

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='#{$accordion-icon-color}'><!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill-rule='evenodd' d='M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' fill='#{$accordion-icon-active-color}'><!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill-rule='evenodd' d='M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z'/></svg>");

//
// Bootstrap Modal CSS variable overrides
//
$modal-sm: 363px;
$modal-md: 556px;
$modal-lg: 943px;
$modal-xl: 1140px;

$modal-content-border-radius: 0;
$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$modal-title-line-height: 28px;

$modal-footer-margin-between: 0;

//
// Bootstrap Badge (Pills) CSS variable overrides
//
$badge-font-size: 16px;
$badge-font-weight: 400;
$badge-color: $black;
$badge-padding-y: 4px;
$badge-padding-x: 12px;
$badge-border-radius: 20px;

//
// Bootstrap Dropdown CSS variable overrides
//
$dropdown-padding-x: 8px;
$dropdown-padding-y: 8px;
$dropdown-border-color: $secondary2;
$dropdown-border-radius: 0;
$dropdown-item-padding-y: 7px;
$dropdown-item-padding-x: 5px;
$dropdown-header-padding-x: 8px;
$dropdown-header-padding-y: 8px;

@mixin dropdown-menu {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      padding: 0;

      a {
        line-height: 24px;
        color: $font-dark-grey;
        border: 3px solid $white;

        &.active {
          background-color: $primary-blue3;
          border-color: $primary-blue3;
        }
        &.disabled:not(.active) {
          color: $font-grey;
        }
        &:hover {
          background-color: $secondary-blue-digital1;
          border-color: $secondary-blue-digital1;
        }
        &:hover:focus-visible,
        &:focus-visible {
          outline: none;
          border-color: $focus-color;
        }

        span {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}

//
// Bootstrap Tabs CSS variable overrides
//
$nav-link-padding-y: 0.25rem;
$nav-link-font-size: 16px;
$nav-link-font-weight: 400;
$nav-link-color: $font-blue;
$nav-link-hover-color: $font-blue;
$nav-link-transition:
  color 0.15s ease-in-out,
  background-color 0.15s ease-in-out,
  border-color 0s ease-in-out;

$nav-link-disabled-color: $font-grey;
$nav-link-focus-box-shadow: 0 0 0 4px $focus-color;

$nav-tabs-link-active-color: $font-blue;

$nav-underline-gap: 32px;
$nav-underline-border-width: 3px;
$nav-underline-link-active-color: $font-blue;

//
// Bootstrap Progress CSS variable overrides
//
$progress-height: 6px;
$progress-bg: $background-grey;
$progress-bar-bg: $secondary2;

//
// Bootstrap Toast CSS variable overrides
//
$toast-max-width: 320px;
$toast-padding-x: 16px;
$toast-padding-y: 16px;
$toast-border-width: 1px;
$toast-border-radius: 0;
$toast-box-shadow: none;
