@use 'variables' as *;

body {
  font-family: $font-family;
  font-weight: 400;
  font-style: normal;
}

//
// Quill editor overrides
//
.ql-editor {
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  padding: 0;
  color: $font-dark-grey;
  line-height: 24px;

  ul {
    padding: 0;
    margin: 0 0 0 18px;

    li {
      list-style-type: disc;
      padding: 0;
    }
  }
}

quill-editor.re-quill-editor {
  .ql-editor {
    padding: 16px;
  }
  .ql-container,
  .ql-toolbar {
    border: 1px solid $secondary2;
  }
}
// TODO: remove fontawesome css
