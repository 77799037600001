@import 'variables';

@import '../../../node_modules/bootstrap/scss/bootstrap';

h1 {
  color: $font-blue;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  padding: 0;
  margin: 0;
}

h2 {
  color: $font-blue;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  padding: 0;
  margin: 0;
}

h3 {
  color: $font-blue;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  padding: 0;
  margin: 0;
}

h4 {
  color: $font-blue;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  padding: 0;
  margin: 0;
}

h5 {
  color: $font-blue;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 0;
  margin: 0;
}

h6 {
  color: $font-blue;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  padding: 0;
  margin: 0;
}

hr {
  color: $secondary-grey3;
  margin: 0;
  opacity: 1;
}

.modal-header .btn-close {
  padding: 10px 12px;
}

.btn-close {
  border-radius: 4px;

  &:hover {
    background-color: $tertiary-bg;
  }
}

a.re-standalone {
  color: $secondary2;
  text-decoration: none;
  border-radius: 1px;

  &:hover {
    color: $primary-hover;
    text-decoration: underline;
  }

  &:visited {
    color: $secondary2;
  }

  &:active {
    color: $primary-active;
    text-decoration: underline;
  }

  &:focus {
    color: $secondary2;
    outline: none;
    box-shadow: 0 0 0 4px $primary;
  }
}
